import { z } from 'zod';

import { RabotEnum } from '../../../enums';

const RabotResTransformer = z.object({
  id: z.string(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  cta: z.string().optional().nullable(),

  
  // type: z.nativeEnum(RabotEnum).optional().nullable(),
  network: z.string().optional().nullable(),
  // userBotWalletAddress: z.string().optional().nullable(),
  // userBotSmartWalletAddress: z.string().optional().nullable(),
  // userBotDepositedAmount: z.coerce.number().optional().nullable(),
  updatedAt: z.coerce.date().optional().nullable(),
  createdAt: z.coerce.date().optional().nullable(),
});

const RabotFullDetailsTransformer = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  logo: z.string().nullable(),
  cta: z.string().nullable(),
  type: z.nativeEnum(RabotEnum),
  network: z.string().nullable(),
  userBotId: z.string().nullable().optional(),
  risks: z.string().nullable(),
  userBotWalletAddress: z.string().nullable(),
  userBotSmartWalletAddress: z.string().nullable(),
  userBotDepositedAmount: z.number().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const CreateRabotReqTransformer = z.object({
  name: z.string(),
  description: z.string().optional().nullable(),
  logo: z.string().optional().nullable(),
  cta: z.string().optional().nullable(),
  network: z.string().optional().nullable(),
});

export const ListRabotsResTransformer = z.array(RabotResTransformer);

export const GetRabotResTransformer = RabotFullDetailsTransformer;
