export enum TransactionTypeEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  SWAP = 'SWAP',
  APPROVE = 'APPROVE',
  APPROVE_LP_TOKEN = 'APPROVE_LP_TOKEN',
  LIQUIDITY_ADD = 'LIQUIDITY_ADD',
  LIQUIDITY_REMOVE = 'LIQUIDITY_REMOVE',
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
  RABOT_FUND = 'RABOT_FUND',
}
