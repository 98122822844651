export enum RabotEnum {
  RENZO = 'RENZO',
  LIDO = 'LIDO',
  AERODROME_WETH_USDC = 'AERODROME_WETH_USDC',
  AERODROME_WETH_DEGEN = 'AERODROME_WETH_DEGEN',
  AERODROME_USDC_MAI = 'AERODROME_USDC_MAI',
  AERODROME_USDC_AERODROME = 'AERODROME_USDC_AERODROME',
  CURVE_CRVUSD_USDC = 'CURVE_CRVUSD_USDC',
  CURVE_CBETH_WETH = 'CURVE_CBETH_WETH',
  CURVE_CRVUSD_SUSD = 'CURVE_CRVUSD_SUSD',
}
