/* eslint-disable no-async-without-await/no-async-without-await */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Account, Ed25519PrivateKey, PrivateKey, PrivateKeyVariants,
} from '@aptos-labs/ts-sdk';
import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
import { getED25519Key } from '@web3auth/auth-adapter';
import { CHAIN_NAMESPACES } from '@web3auth/base';
import { CommonPrivateKeyProvider } from '@web3auth/base-provider';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { Web3Auth } from '@web3auth/single-factor-auth';
import {
  SolanaPrivateKeyProvider,
  SolanaWallet,
} from '@web3auth/solana-provider';
import * as jose from 'jose';
import mixpanel from 'mixpanel-browser';

import type {
  ApiUpdateAuthorizationError,
  ApiUpdateAuthorizationState,
  ApiUpdateConnectionState,
  ApiUpdateCurrentUser,
  ApiUpdateServerTimeOffset,
  ApiUpdateSession,
  ApiUser,
} from '../../../api/types';
import type { RequiredGlobalActions } from '../../index';
import type { ActionReturnType, GlobalState } from '../../types';

import { SESSION_USER_KEY } from '../../../config';
import pluto from '../../../lib/pluto';
import { EventEnum } from '../../../lib/pluto/enums';
import { getCurrentTabId } from '../../../util/establishMultitabRole';
import { getShippingError, shouldClosePaymentModal } from '../../../util/getReadableErrorText';
import { unique } from '../../../util/iteratees';
import { oldSetLanguage } from '../../../util/oldLangProvider';
import { clearWebTokenAuth } from '../../../util/routing';
import { setServerTimeOffset } from '../../../util/serverTime';
import { forceWebsync } from '../../../util/websync';
import { encryptData } from '../../../components/right/wallet/util';
import { isChatChannel, isChatSuperGroup } from '../../helpers';
import {
  addActionHandler, getActions, getGlobal, setGlobal,
} from '../../index';
import {
  setWeb3AuthProvider,
  updateUser, updateUserFullInfo, updateUserWeb3AuthWalletInfo,
} from '../../reducers';
import { updateTabState } from '../../reducers/tabs';
import { selectTabState } from '../../selectors';
// import { createUser } from './rabble';

addActionHandler('apiUpdate', (global, actions, update): ActionReturnType => {
  switch (update['@type']) {
    case 'updateApiReady':
      onUpdateApiReady(global);
      break;

    case 'updateAuthorizationState':
      onUpdateAuthorizationState(global, update);
      break;

    case 'updateAuthorizationError':
      onUpdateAuthorizationError(global, update);
      break;

    case 'updateWebAuthTokenFailed':
      onUpdateWebAuthTokenFailed(global);
      break;

    case 'updateConnectionState':
      onUpdateConnectionState(global, actions, update);
      break;

    case 'updateSession':
      onUpdateSession(global, actions, update);
      break;

    case 'updateServerTimeOffset':
      onUpdateServerTimeOffset(update);
      break;

    case 'updateCurrentUser':
      onUpdateCurrentUser(global, update);
      break;

    case 'requestReconnectApi':
      global = { ...global, isSynced: false };
      setGlobal(global);

      onUpdateConnectionState(global, actions, {
        '@type': 'updateConnectionState',
        connectionState: 'connectionStateConnecting',
      });
      actions.initApi();
      break;

    case 'requestSync':
      actions.sync();
      break;

    case 'updateFetchingDifference':
      global = { ...global, isFetchingDifference: update.isFetching };
      setGlobal(global);
      break;

    case 'error': {
      Object.values(global.byTabId).forEach(({ id: tabId }) => {
        const paymentShippingError = getShippingError(update.error);
        if (paymentShippingError) {
          actions.addPaymentError({ error: paymentShippingError, tabId });
        } else if (shouldClosePaymentModal(update.error)) {
          actions.closePaymentModal({ tabId });
        } else if (actions.showDialog) {
          actions.showDialog({ data: update.error, tabId });
        }
      });

      break;
    }
  }
});

function onUpdateApiReady<T extends GlobalState>(global: T) {
  void oldSetLanguage(global.settings.byKey.language);
}

function onUpdateAuthorizationState<T extends GlobalState>(global: T, update: ApiUpdateAuthorizationState) {
  global = getGlobal();

  const wasAuthReady = global.authState === 'authorizationStateReady';
  const authState = update.authorizationState;

  global = {
    ...global,
    authState,
    authIsLoading: false,
  };
  setGlobal(global);

  global = getGlobal();

  switch (authState) {
    case 'authorizationStateLoggingOut':
      void forceWebsync(false);

      global = {
        ...global,
        isLoggingOut: true,
      };
      setGlobal(global);
      break;
    case 'authorizationStateWaitCode':
      global = {
        ...global,
        authIsCodeViaApp: update.isCodeViaApp,
      };
      setGlobal(global);
      break;
    case 'authorizationStateWaitPassword':
      global = {
        ...global,
        authHint: update.hint,
      };

      if (update.noReset) {
        global = {
          ...global,
          hasWebAuthTokenPasswordRequired: true,
        };
      }

      setGlobal(global);
      break;
    case 'authorizationStateWaitQrCode':
      global = {
        ...global,
        authIsLoadingQrCode: false,
        authQrCode: update.qrCode,
      };
      setGlobal(global);
      break;
    case 'authorizationStateReady': {
      if (wasAuthReady) {
        break;
      }

      void forceWebsync(true);

      global = {
        ...global,
        isLoggingOut: false,
      };
      Object.values(global.byTabId).forEach(({ id: tabId }) => {
        global = updateTabState(global, {
          isInactive: false,
        }, tabId);
      });
      setGlobal(global);

      break;
    }
  }
}

function onUpdateAuthorizationError<T extends GlobalState>(global: T, update: ApiUpdateAuthorizationError) {
  global = getGlobal();
  global = {
    ...global,
    authError: update.message,
  };
  setGlobal(global);
}

function onUpdateWebAuthTokenFailed<T extends GlobalState>(global: T) {
  clearWebTokenAuth();
  global = getGlobal();

  global = {
    ...global,
    hasWebAuthTokenFailed: true,
  };
  setGlobal(global);
}

function onUpdateConnectionState<T extends GlobalState>(
  global: T, actions: RequiredGlobalActions, update: ApiUpdateConnectionState,
) {
  const { connectionState } = update;

  global = getGlobal();
  const tabState = selectTabState(global, getCurrentTabId());
  if (connectionState === 'connectionStateReady' && tabState.isMasterTab && tabState.multitabNextAction) {
    // @ts-ignore
    actions[tabState.multitabNextAction.action](tabState.multitabNextAction.payload);
    actions.clearMultitabNextAction({ tabId: tabState.id });
  }

  if (connectionState === global.connectionState) {
    return;
  }

  global = {
    ...global,
    connectionState,
  };
  setGlobal(global);

  if (global.isSynced) {
    const channelStackIds = Object.values(global.byTabId)
      .flatMap((tab) => tab.messageLists)
      .map((messageList) => messageList.chatId)
      .filter((chatId) => {
        const chat = global.chats.byId[chatId];
        return chat && (isChatChannel(chat) || isChatSuperGroup(chat));
      });
    if (connectionState === 'connectionStateReady' && channelStackIds.length) {
      unique(channelStackIds).forEach((chatId) => {
        actions.requestChannelDifference({ chatId });
      });
    }
  }

  if (connectionState === 'connectionStateBroken') {
    actions.signOut({ forceInitApi: true });
  }
}

function onUpdateSession<T extends GlobalState>(global: T, actions: RequiredGlobalActions, update: ApiUpdateSession) {
  const { sessionData } = update;
  global = getGlobal();
  const { authRememberMe, authState } = global;
  const isEmpty = !sessionData || !sessionData.mainDcId;

  if (!authRememberMe || authState !== 'authorizationStateReady' || isEmpty) {
    return;
  }

  actions.saveSession({ sessionData });
}

function onUpdateServerTimeOffset(update: ApiUpdateServerTimeOffset) {
  setServerTimeOffset(update.serverTimeOffset);
}

async function generateJwtToken(telegramUserId: string) {
  const privateKeyPem = process.env.WEB3_AUTH_PRIVATE_KEY!;

  const privateKey = await jose.importPKCS8(privateKeyPem, 'RS256');

  const token = await new jose.SignJWT({
    iss: 'https://rabblelabs.xyz',
    id: telegramUserId,
    iat: Math.floor(Date.now() / 1000),
  })
    .setProtectedHeader({ alg: 'RS256', kid: '694cc9d4bdc3d271de78b44253068' })
    .sign(privateKey);

  return token;
}

async function web3AuthConnect(telegramUserId: string) {
  // const clientId = process.env.WEB3_AUTH_CLIENT_ID!;
  const clientId = 'BGq8fNlCmyWWsbV_XuMq07HzDaN-DgIsIMYz_ObuvEmzKHjHHM9dzQK0IXVPWMOdXVj5kDLSgl11jsd4ptRNLm0';

  const chainConfig = {
    chainId: '0x89',
    displayName: 'Polygon Mainnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    tickerName: 'MATIC',
    ticker: 'MATIC',
    decimals: 18,
    rpcTarget: 'https://rpc.ankr.com/polygon/01e68499d32f39afb838226c738d21f57518de3d60ca18aa6697bc8822aedb2f',
    blockExplorerUrl: 'https://polygonscan.com',
  };

  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
  });

  const solanaProvider = new SolanaPrivateKeyProvider({
    config: {
      chainConfig: {
        chainNamespace: 'solana',
        chainId: '0x2',
        rpcTarget: 'https://api.testnet.solana.com',
        displayName: 'Solana Mainnet',
        blockExplorerUrl: 'https://explorer.solana.com/',
        ticker: 'SOL',
        tickerName: 'Solana',
      },
    },
  });

  const aptosProvider = new CommonPrivateKeyProvider({
    config: {
      chainConfig: {
        chainNamespace: 'other',
        chainId: '0x1',
        rpcTarget: 'https://rpc.ankr.com/http/aptos/v1',
        displayName: 'Aptos Mainnet',
        blockExplorerUrl: 'https://explorer.aptoslabs.com/',
        ticker: 'APT',
        tickerName: 'Aptos',
      },
    },
  });

  const web3auth = new Web3Auth({
    clientId,
    web3AuthNetwork: 'mainnet',
    privateKeyProvider,
  });

  await web3auth.init();

  const {
    state, status, connected, provider,
  } = web3auth;

  const token = await generateJwtToken(telegramUserId);
  let initProvider = provider;

  if (!web3auth.connected) {
    try {
      initProvider = await web3auth.connect({
        verifier: 'rabble-legacy-mainnet-verifier',
        verifierId: telegramUserId,
        idToken: token!,
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  let ethWalletAddress;
  let eth_private_key;
  let solanaPrivateKey;
  let solanaAddress;
  let aptosAddress;
  let suiKeyPair;

  if (initProvider) {
    eth_private_key = await initProvider?.request({
      method: 'eth_private_key',
    });
    const eth_address = await initProvider?.request({ method: 'eth_accounts' });
    ethWalletAddress = (eth_address as any)[0];

    // Solana wallet
    const ed25519key = getED25519Key(eth_private_key as string).sk.toString('hex');
    await solanaProvider.setupProvider(ed25519key);
    const solanaWallet = new SolanaWallet(solanaProvider);

    const solanaAddresses = await solanaWallet.requestAccounts();
    solanaPrivateKey = await solanaWallet.request({
      method: 'solanaPrivateKey',
    });
    solanaAddress = solanaAddresses[0];

    // Aptos wallet
    await aptosProvider.setupProvider(ed25519key);

    const formattedAptosPrivateKey = PrivateKey.formatPrivateKey(eth_private_key as string, PrivateKeyVariants.Ed25519);
    const privateKeyInEd25519 = new Ed25519PrivateKey(formattedAptosPrivateKey);
    const aptosAccount = Account.fromPrivateKey({
      privateKey: privateKeyInEd25519,
    });
    aptosAddress = aptosAccount.accountAddress.toString();

    // Sui Wallet
    const ethPrivateKey: string = eth_private_key as string;
    const privateKeyUint8Array = new Uint8Array(
      ethPrivateKey.match(/.{1,2}/g)!.map((byte: any) => parseInt(byte, 16))
    );
    suiKeyPair = Ed25519Keypair.fromSecretKey(privateKeyUint8Array);
  }

  return {
    ethWalletAddress,
    ethPrivateKey: eth_private_key,
    solPrivateKey: solanaPrivateKey,
    solAddress: solanaAddress,
    aptosAddress,
    aptosPrivateKey: `0x${eth_private_key}`,
    suiAddress: suiKeyPair?.toSuiAddress(),
    suiPrivateKey: suiKeyPair?.getSecretKey()
  };
}

async function onUpdateCurrentUser<T extends GlobalState>(global: T, update: ApiUpdateCurrentUser) {
  const { currentUser, currentUserFullInfo } = update;
  const privateKeyEncrptionSecretKey = process.env.PRIVATE_KEY_ENCRYPTION_SECRET_KEY;

  global = {
    ...updateUser(global, currentUser.id, currentUser),
    currentUserId: currentUser.id,
  };
  global = updateUserFullInfo(global, currentUser.id, currentUserFullInfo);
  setGlobal(global);

  updateSessionUserId(currentUser.id);

  // mixpanel identify
  mixpanel.identify(currentUser.id);

  // web3Auth
  let web3AuthEthWalletAddress = '';
  let web3AuthEthWalletPrivateKey = '';
  let web3AuthSolWalletAddress = '';
  let web3AuthSolWalletPrivateKey = '';
  let web3AuthAptosWalletAddress = '';
  let web3AuthAptosWalletPrivateKey = '';
  let web3AuthSuiWalletAddress = '';
  let web3AuthSuiWalletPrivateKey = '';

  try {
    const {
      ethWalletAddress,
      ethPrivateKey,
      solPrivateKey,
      solAddress,
      aptosAddress,
      aptosPrivateKey,
      suiAddress,
      suiPrivateKey
    } = await web3AuthConnect(currentUser.id);

    web3AuthEthWalletAddress = ethWalletAddress;
    web3AuthEthWalletPrivateKey = ethPrivateKey as string;
    web3AuthSolWalletAddress = solAddress as string;
    web3AuthSolWalletPrivateKey = solPrivateKey as string;
    web3AuthAptosWalletAddress = aptosAddress as string;
    web3AuthAptosWalletPrivateKey = aptosPrivateKey as string;
    web3AuthSuiWalletAddress = suiAddress as string;
    web3AuthSuiWalletPrivateKey = suiPrivateKey as string;
    const encryptedEthPrivateKey = encryptData(web3AuthEthWalletPrivateKey, privateKeyEncrptionSecretKey!);
    const encryptedSolPrivateKey = encryptData(web3AuthSolWalletPrivateKey, privateKeyEncrptionSecretKey!);
    const encryptedAptosPrivateKey = encryptData(web3AuthAptosWalletPrivateKey, privateKeyEncrptionSecretKey!);
    const encryptedSuiPrivateKey = encryptData(web3AuthSuiWalletPrivateKey, privateKeyEncrptionSecretKey!);
    updateUserWeb3AuthWalletInfo(global, web3AuthEthWalletAddress, encryptedEthPrivateKey, web3AuthSolWalletAddress, encryptedSolPrivateKey, web3AuthAptosWalletAddress, encryptedAptosPrivateKey, web3AuthSuiWalletAddress, encryptedSuiPrivateKey);
    mixpanel.track('Get Web3Auth Wallet Success', {
      use: 'TECH',
      ethWalletAddress: web3AuthEthWalletAddress,
      solWalletAddress: web3AuthSolWalletAddress,
    });
  } catch (error) {
    //
  }

  // pluto connect
  await pluto.connect(
    currentUser.id,
    currentUser.firstName,
    currentUser.lastName,
    currentUser.usernames?.[0]?.username,
    web3AuthEthWalletAddress,
    web3AuthSolWalletAddress,
    web3AuthAptosWalletAddress,
    web3AuthSuiWalletAddress,
  );

  // const { loginStreak } = await pluto.services.earn.getAppVisitCount();
  // console.log("above the call")
  // const { inviteAcceptedCount } = await pluto.services.earn.getInviteAcceptedCount();
  // console.log("below the call")
  // console.log({inviteAcceptedCount})
  // setAppVisitCount({ appVisitCount: loginStreak });
  // setInviteAcceptedCount({ inviteAcceptedCount });
  await pluto.services.earn.createEvent({ event: EventEnum.APP_VISIT_0 });
}

function getUserFullname(user: ApiUser): string {
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  return `${firstName} ${lastName}`.trim();
}

function getUserUsername(user: ApiUser): string {
  return user.usernames?.filter(Boolean).filter((username) => username.isActive)?.[0]?.username ?? '';
}

function updateSessionUserId(currentUserId: string) {
  const sessionUserAuth = localStorage.getItem(SESSION_USER_KEY);
  if (!sessionUserAuth) return;

  const userAuth = JSON.parse(sessionUserAuth);
  userAuth.id = currentUserId;

  localStorage.setItem(SESSION_USER_KEY, JSON.stringify(userAuth));
}
